import { useInfiniteQuery, useQuery } from "react-query"
import api from ".."
import { TApiResponse } from "../../types/api"


type TuseRequest = {
    command: string,
    requestData?: { [key: string]: any },
    enabled?: boolean,
    refetchInterval?: number,

}

const useMessenger = <dataType = any>({ command, requestData = {}, enabled = true, refetchInterval = 0 }: TuseRequest) => {

    const fetchKey = ["messengers", command, requestData]


    const { isLoading,
        isFetching,
        isRefetching,
        error,
        data,
        hasNextPage,
        refetch,
        fetchNextPage } = useInfiniteQuery(fetchKey, ({ pageParam = undefined, signal }) => api<dataType>("messengers", command, { ...requestData, page: pageParam }, { signal }), {
            retry: false,
            refetchOnWindowFocus: false,
            enabled,
            refetchInterval,
            getNextPageParam: (lastPage) => {
                return lastPage.detail.next_page ?? undefined
            }
        })
    return {
        isLoading,
        isFetching,
        isRefetching,
        error,
        data,
        hasNextPage,
        refetch,
        fetchNextPage
    }
}

export default useMessenger